import { EventDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { EventsContext, EventsContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEvents extends Omit<EventsContextModel, 'store'> {
  /**
   * The events for the current event configuration.
   */
  events: EventDoc[] | null;
  /**
   * Any errors that occurred while fetching the events.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the events and error state from the `EventsContext`.
 * @returns An object containing the `events` and `error` state, as well as any additional properties passed to the `EventProvider`.
 * @throws An error if used outside of the `EventProvider`.
 */
export function useEvents(): UseEvents {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error('useEvents must be used within EventProvider');
  }

  const { store, ...rest } = context;
  const [events, error] = useStore(store);

  return {
    events,
    error,
    ...rest,
  };
}
