import { TrackPlaybackHistory } from '@livekatsomo/models';
import { emptyStore, useStore } from '@livekatsomo/suspense';
import {
  TrackPlaybackHistoryContext,
  TrackPlaybackHistoryContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseTrackPlaybackHistory
  extends Omit<TrackPlaybackHistoryContextModel, 'store'> {
  /**
   * The user's track playback history.
   */
  trackPlaybackHistory: TrackPlaybackHistory | null;
  /**
   * Any errors that occurred while fetching the user's track playback history.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the user's track playback history.
 * @returns An object containing the user's track playback history, any errors that occurred while retrieving the history, and any additional context provided by the TrackPlaybackHistoryProvider.
 * @throws An error if used outside of the TrackPlaybackHistoryProvider.
 */
export function useTrackPlaybackHistory(): UseTrackPlaybackHistory {
  const context = useContext(TrackPlaybackHistoryContext);
  if (context === undefined) {
    throw new Error(
      'useTrackPlaybackHistory must be used within TrackPlaybackHistoryProvider',
    );
  }

  const { store, ...rest } = context;
  const [trackPlaybackHistory, error] = useStore(store || emptyStore);

  return { trackPlaybackHistory, error, ...rest };
}
