import { AssetDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  AssetsContextModel,
  CustomerAssetsContext,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseCustomerAssets extends Omit<AssetsContextModel, 'store'> {
  /**
   * The customer assets.
   * @default null
   */
  assets: AssetDoc[] | null;
  /**
   * Any errors that occurred while fetching the customer assets.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the customer assets and error state.
 * @returns An object containing the assets and error state.
 * @throws If used outside of the CustomerAssetsProvider.
 */
export function useCustomerAssets(): UseCustomerAssets {
  const context = useContext(CustomerAssetsContext);
  if (context === undefined) {
    throw new Error(
      'useCustomerAssets must be used within CustomerAssetsProvider',
    );
  }

  const { store, ...rest } = context;

  const [assets, error] = useStore(store);

  return {
    assets,
    error,
    ...rest,
  };
}
