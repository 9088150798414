import { useStore } from '@livekatsomo/suspense';
import { SystemConfigContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that provides access to the system configuration.
 * @returns An object containing the system configuration, any errors, and other context values.
 * @throws Will throw an error if used outside of the SystemConfigProvider.
 */
export function useSystemConfig() {
  const context = useContext(SystemConfigContext);
  if (context === undefined) {
    throw new Error('useSystemConfig must be used within SystemConfigProvider');
  }
  const { store, ...rest } = context;
  const [systemConfig, error] = useStore(store);

  return { systemConfig, error, ...rest };
}
