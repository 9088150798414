import { useStore } from '@livekatsomo/suspense';
import { TicketmasterStatusContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that provides access to the Ticketmaster status and error state.
 * @returns An object containing the Ticketmaster status, error, and any additional context provided by the TicketmasterStatusProvider.
 * @throws An error if used outside of a TicketmasterStatusProvider.
 */
export function useTicketmasterStatus() {
  const context = useContext(TicketmasterStatusContext);
  if (context === undefined) {
    throw new Error(
      'useTicketmasterStatus must be used within TicketmasterStatusProvider',
    );
  }
  const { store, ...rest } = context;
  const [ticketmasterStatus, error] = useStore(store);

  return { ticketmasterStatus, error, ...rest };
}
