import { Agenda } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { AgendaContext, AgendaContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';
import { useTrack } from './useTrack';

/**
 * Represents the return value of the `useAgenda` hook.
 */
export interface UseAgenda extends Omit<AgendaContextModel, 'store'> {
  /**
   * The agenda items for the current track.
   */
  agendaItems: Agenda;
  /**
   * Any errors that occurred while fetching the agenda items.
   */
  error: Error | null;
}

/**
 * A hook that provides the agenda items for the current track.
 * @returns An object containing the sorted agenda items and any errors that occurred while fetching the data.
 * @throws Will throw an error if the track is not found or if the hook is used outside of an AgendaProvider.
 */
export function useAgenda(): UseAgenda {
  const context = useContext(AgendaContext);

  const { track } = useTrack();

  if (!track) throw new Error('Track not found');

  if (context === undefined) {
    throw new Error('useAgenda must be used within AgendaProvider');
  }
  const { store, ...rest } = context;
  const [agendaItems, error] = useStore(store);

  const sortedAgenda =
    agendaItems && track.agendaItems
      ? agendaItems
          .slice()
          .sort(
            (a, b) =>
              (track.agendaItems || []).indexOf(a.id) -
              (track.agendaItems || []).indexOf(b.id),
          )
      : [];

  return { agendaItems: sortedAgenda, error, ...rest };
}
