import { FormDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  SurveyFormContext,
  SurveyFormContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseSurveyForm extends Omit<SurveyFormContextModel, 'store'> {
  /**
   * The selected survey form.
   */
  form: FormDoc | null;
  /**
   * Any errors that occurred while fetching the event forms.
   */
  error: Error | null;
}

/**
 * A hook that provides access to survey form and error state.
 * @returns An object containing the form, error state and associated methods.
 * @throws Will throw an error if used outside of the SurveyFormsProvider.
 */
export function useSurveyForm(): UseSurveyForm {
  const context = useContext(SurveyFormContext);
  if (context === undefined) {
    throw new Error('useSurveyForm must be used within SurveyFormProvider');
  }

  const { store, ...rest } = context;

  const [form, error] = useStore(store);

  return {
    form,
    error,
    ...rest,
  };
}
