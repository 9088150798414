import { CustomerDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  CustomerContext,
  CustomerContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseCustomer extends Omit<CustomerContextModel, 'store'> {
  /**
   * The current customer.
   */
  customer: CustomerDoc | null;
  /**
   * Any errors that occurred while fetching the customer.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the current customer and error state.
 * @returns An object containing the current customer and error state.
 * @throws An error if used outside of a CustomerProvider.
 */
export function useCustomer(): UseCustomer {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomer must be used within CustomerProvider');
  }
  const { store, ...rest } = context;

  console.log('useCustomer store', store);
  const [customer, error] = useStore(store);

  if (error) {
    console.error(error);
  } else {
    console.log('useCustomer customer', customer);
  }

  return {
    ...rest,
    customer,
    error,
  };
}
