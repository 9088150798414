import { CustomerDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  CustomersContext,
  CustomersContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseCustomers extends Omit<CustomersContextModel, 'store'> {
  /**
   * The customers.
   */
  customers: CustomerDoc[] | null;
  /**
   * Any errors that occurred while fetching the customers.
   */
  error: Error | null;
}

/**
 * Hook to get customerStore from context.
 *
 * Will throw error if used outside of CustomersProvider.
 *
 * @returns Customers and customers specific functions.
 */
export function useCustomers(): UseCustomers {
  const context = useContext(CustomersContext);
  if (context === undefined) {
    throw new Error('useCustomers must be used within CustomersProvider');
  }
  const { store, ...rest } = context;
  const [customers, error] = useStore(store);

  return { customers, error, ...rest };
}
