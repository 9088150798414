import { ThemeDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  CustomerThemesContext,
  CustomerThemesContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseCustomerThemes
  extends Omit<CustomerThemesContextModel, 'store'> {
  /**
   * The customer themes.
   */
  themes: ThemeDoc[] | null;
  /**
   * Any errors that occurred while fetching the customer themes.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the customer themes and error state.
 * @returns An object containing the themes, theme methods and error state.
 * @throws If used outside of the CustomerThemesProvider.
 */
export function useCustomerThemes(): UseCustomerThemes {
  const context = useContext(CustomerThemesContext);
  if (context === undefined) {
    throw new Error(
      'useCustomerThemes must be used within CustomerThemesProvider',
    );
  }
  const { store, ...rest } = context;
  const [themes, error] = useStore(store);

  return {
    ...rest,
    error,
    themes,
  };
}
