import { PlayerContext, PlayerContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that returns the player context.
 * @returns The player context.
 * @throws If used outside of the PlayerProvider.
 */
export function usePlayer(): PlayerContextModel {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error('usePlayer must be used within PlayerProvider');
  }

  return context;
}
