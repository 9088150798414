import { TrackDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { TracksContext, TracksContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';
import { useEvent } from './useEvent';

export interface UseTracks extends Omit<TracksContextModel, 'store'> {
  /**
   * The tracks for the current event.
   */
  tracks: TrackDoc[];
  /**
   * Any errors that occurred while fetching the tracks.
   */
  error: Error | null;
}

/**
 * Custom hook that returns an object containing an array of sorted tracks and an error message.
 * @returns {UseTracks} An object containing an array of sorted tracks and an error message.
 * @throws If used outside of TracksProvider or if event is not found.
 */
export function useTracks(): UseTracks {
  const context = useContext(TracksContext);
  const { event } = useEvent();
  if (context === undefined) {
    throw new Error('useTracks must be used within TracksProvider');
  }
  if (!event) throw new Error('Event not found');
  const { store, ...rest } = context;
  const [tracks, error] = useStore(store);

  const sortedTracks =
    tracks && event.tracks
      ? tracks
          .slice()
          .sort(
            (a, b) =>
              (event.tracks || []).indexOf(a.id) -
              (event.tracks || []).indexOf(b.id),
          )
      : [];

  return { tracks: sortedTracks, error, ...rest };
}
