import { AnalyticsContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that returns the analytics context from the nearest AnalyticsProvider.
 * @returns The analytics context.
 * @throws An error if used outside of an AnalyticsProvider.
 */
export function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticss must be used within AnalyticssProvider');
  }

  return context;
}
