import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNotification } from './useNotification';
import { useTranslation } from 'next-i18next';

/**
 * A hook that listens for notifications and displays them using the snackbar.
 */
export function useNotificationListener() {
  const { t } = useTranslation();
  const { notificationMessages } = useNotification();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (notificationMessages) {
      notificationMessages.forEach((message) => {
        console.log('message', message);
        enqueueSnackbar(message.message, {
          variant: message.variant,
          persist: message.persist,
          autoHideDuration: message.autoHideDuration,
          disableWindowBlurListener: true,
          key: message.id,
          action: (
            <Button
              sx={{ color: 'inherit' }}
              onClick={() => closeSnackbar(message.id)}
            >
              {t('hide')}
            </Button>
          ),
        });
      });
    }
  }, [closeSnackbar, enqueueSnackbar, notificationMessages, t]);
}
