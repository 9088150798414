import { useStore } from '@livekatsomo/suspense';
import {
  ThemeEditorContext,
  ThemeEditorContextModel,
} from '@livekatsomo/web/contexts';
import { ThemeOptions } from '@mui/material/styles';
import { useContext } from 'react';

export interface UseThemeEditor extends Omit<ThemeEditorContextModel, 'store'> {
  /**
   * The current theme.
   */
  theme: ThemeOptions | null;
  /**
   * Any errors that occurred while fetching the theme.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the theme editor context.
 * @returns An object containing the current theme, error, and other context values.
 * @throws If used outside of a ThemeProvider.
 */
export function useThemeEditor(): UseThemeEditor {
  const context = useContext(ThemeEditorContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within ThemeProvider');
  }
  const { store, ...rest } = context;
  const [theme, error] = useStore(store);

  return { theme, error, ...rest };
}
