import { NotificationMessageDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  NotificationContext,
  NotificationContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseNotification
  extends Omit<NotificationContextModel, 'store'> {
  /**
   * The notification messages to display.
   */
  notificationMessages: NotificationMessageDoc[] | null;
  /**
   * Any errors that occurred while fetching the notification messages.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the notification messages and error from the NotificationContext.
 * @throws If used outside of NotificationProvider.
 * @returns An object containing notificationMessages, error, and any additional properties passed to NotificationProvider.
 */
export function useNotification(): UseNotification {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within NotificationProvider');
  }

  const { store, ...rest } = context;
  const [notificationMessages, error] = useStore(store);
  return {
    notificationMessages,
    error,
    ...rest,
  };
}
