import {
  KatsomoAuthError,
  KatsomoAuthErrorCodes,
} from '@livekatsomo/custom-errors';
import { User } from '@livekatsomo/models';
import { UseAuthentication, useAuthentication } from './useAuthentication';

export type UseLoggedInUser = Omit<UseAuthentication, 'user'> &
  (
    | {
        /**
         * The currently logged in user.
         */
        user: User;
        /**
         * Error is always null if the user is logged in.
         */
        error: null;
      }
    | {
        /**
         * User is always null if the user is not logged in.
         */
        user: null;
        /**
         * The error that occurred while fetching the user.
         */
        error: KatsomoAuthError;
      }
  );

/**
 * A hook that returns the currently logged in user, or an error if the user is not logged in.
 * @returns An object containing the user and error, or null if the user is not logged in.
 */
export function useLoggedInUser(): UseLoggedInUser {
  const { user, error, ...rest } = useAuthentication();

  if (!user) {
    return {
      user: null,
      error: new KatsomoAuthError(
        'User is not logged in',
        KatsomoAuthErrorCodes.AUTHENTICATION_REQUIRED,
      ),
      ...rest,
    };
  }

  return {
    user,
    error: null,
    ...rest,
  };
}
