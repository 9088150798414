import {
  AuthorizationContext,
  AuthorizationContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that returns the authorization context.
 * @returns The authorization context.
 * @throws If used outside of an AuthorizationProvider.
 */
export function useAuthorization(): AuthorizationContextModel {
  const context = useContext(AuthorizationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthorization must be used within AuthorizationProvider',
    );
  }

  return context;
}
