import { AssetDoc, SlideQueueItemDoc } from '@livekatsomo/models';
import { emptyStore, useStore } from '@livekatsomo/suspense';
import {
  SlideControllerContext,
  SlideControllerContextModel,
} from '@livekatsomo/web/contexts';
import { useContext, useMemo } from 'react';
import { useTrack } from './useTrack';
import { slideQueueItemsToSlideSyncModelReducer } from '@livekatsomo/shared/utils';

export interface UseSlideController
  extends Omit<SlideControllerContextModel, 'store'> {
  /**
   * The slide queue.
   */
  slideQueue: SlideQueueItemDoc[] | null;
  /**
   * The slides in the slide queue.
   */
  slides: AssetDoc[] | null;
  /**
   * Any errors that occurred while fetching the slide queue.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the slide controller context and slide queue.
 * @returns An object containing the slide queue, slides, error, and other context values.
 * @throws An error if used outside of the SlideControllerProvider.
 */
export function useSlideController(): UseSlideController {
  const context = useContext(SlideControllerContext);
  const { track } = useTrack();
  if (context === undefined) {
    throw new Error(
      'useSlideController must be used within SlideControllerProvider',
    );
  }

  const { store, ...rest } = context;
  const [slideQueue, error] = useStore(store || emptyStore);
  const slideQueueWithTimes = useMemo(
    () =>
      slideQueue ? slideQueueItemsToSlideSyncModelReducer(slideQueue) : [],
    [slideQueue],
  );
  const slideQueueWithTimesFiltered = useMemo(
    () =>
      slideQueueWithTimes.filter(
        (slide) =>
          // If the track has started, only show slides that have not ended yet
          !slide.end ||
          // If the track has not started, include all slides
          !track?.actualStartTime ||
          // If the track has started, only show slides that have not ended yet
          slide.end?.getTime() > track?.actualStartTime.getTime(),
      ),
    [slideQueueWithTimes, track?.actualStartTime],
  );
  const slides = useMemo(
    () =>
      slideQueueItemsToSlideSyncModelReducer(slideQueueWithTimesFiltered).map(
        (item) =>
          ({
            ...item.asset,
            id: item.id,
            docType: 'asset',
          }) satisfies AssetDoc,
      ),
    [slideQueueWithTimesFiltered],
  );

  return {
    slideQueue: slideQueueWithTimesFiltered,
    slides,
    error,
    ...rest,
  };
}
