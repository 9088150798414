import { KatsomoError } from '@livekatsomo/custom-errors';
import {
  SuperAdminContext,
  SuperAdminContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';
import { useAdminUser } from './useAdminUser';

/**
 * A hook that provides the super admin context and checks if the user is authorized to access the super admin page.
 * @returns The super admin context and error, if any.
 * @throws If used outside of the SuperAdminProvider.
 */
export function useSuperAdmin(): SuperAdminContextModel {
  const context = useContext(SuperAdminContext);
  const { user, error } = useAdminUser();
  let superAdminError = error;
  if (context === undefined) {
    throw new Error('useSuperAdmin must be used within SuperAdminProvider');
  }

  if (!user?.roles?.includes('super-admin')) {
    superAdminError = new KatsomoError(
      'Unauthorized admin user tried to access super admin page',
    );
  }

  return { ...context, error: superAdminError };
}
