import { SlideQueueItemDoc, SlideSyncModel } from '@livekatsomo/models';

/**
 * Reducer function that converts an array of SlideQueueItemDoc objects to an array of SlideSyncModel objects.
 * @param slideChangeEvents An array of SlideQueueItemDoc objects representing slide change events.
 * @returns An array of SlideSyncModel objects representing slide change events with additional properties.
 */
export function slideQueueItemsToSlideSyncModelReducer(
  slideChangeEvents: SlideQueueItemDoc[],
) {
  return slideChangeEvents.reduce(
    (prev, curr, index) => {
      const start = curr.start || curr.timestamp;
      const nextSlide = slideChangeEvents[index + 1];
      const end = curr.end || nextSlide?.start || nextSlide?.timestamp;
      const slide = {
        ...curr,
        start,
        index,
        end,
      } satisfies SlideSyncModel;
      return [...prev, slide];
    },
    [] as Array<SlideQueueItemDoc & SlideSyncModel>,
  );
}
