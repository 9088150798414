import { SpeakerDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  SpeakersContext,
  SpeakersContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';
import { useEvent } from './useEvent';

export interface UseSpeakers extends Omit<SpeakersContextModel, 'store'> {
  /**
   * The list of speakers.
   */
  speakers: SpeakerDoc[] | null;
  /**
   * Any errors that occurred while fetching the speakers.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the list of speakers and any errors that occur while fetching them.
 * @returns An object containing the list of speakers, any errors that occurred while fetching them, and any additional context provided by the `SpeakerProvider`.
 * @throws An error if used outside of a `SpeakerProvider`.
 */
export function useSpeakers(): UseSpeakers {
  const context = useContext(SpeakersContext);
  const { event } = useEvent();

  if (!event) throw new Error('Event not found');

  if (context === undefined) {
    throw new Error('useSpeaker must be used within SpeakerProvider');
  }
  const { store, ...rest } = context;
  const [speakers, error] = useStore(store);

  const sortedSpeakers =
    speakers && event.speakers
      ? speakers
          .slice()
          .sort(
            (a, b) =>
              (event.speakers || []).indexOf(a.id) -
              (event.speakers || []).indexOf(b.id),
          )
      : speakers;

  return { speakers: sortedSpeakers, error, ...rest };
}
