import { User } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { UsersContext, UsersContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseUsers extends Omit<UsersContextModel, 'store'> {
  /**
   * The users data.
   */
  users: User[] | null;
  /**
   * Any errors that occurred while fetching the users data.
   */
  error: Error | null;
}

/**
 * Custom hook that provides access to the users data and error state.
 * @returns An object containing the users data, error state, and other context values.
 * @throws If used outside of the UsersProvider.
 */
export function useUsers(): UseUsers {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within UsersProvider');
  }
  const { store, ...rest } = context;

  const [users, error] = useStore(store);

  return { users, error, ...rest };
}
