import { FormDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  FormsContextModel,
  EventFormsContext,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEventForms extends Omit<FormsContextModel, 'store'> {
  /**
   * The event forms.
   */
  forms: FormDoc[] | null;
  /**
   * Any errors that occurred while fetching the event forms.
   */
  error: Error | null;
}

/**
 * A hook that provides access to event forms and error state.
 * @returns An object containing the event forms and error state.
 * @throws Will throw an error if used outside of the EventFormsProvider.
 */
export function useEventForms(): UseEventForms {
  const context = useContext(EventFormsContext);
  if (context === undefined) {
    throw new Error('useEventForms must be used within EventFormsProvider');
  }

  const { store, ...rest } = context;

  const [forms, error] = useStore(store);

  return {
    forms,
    error,
    ...rest,
  };
}
