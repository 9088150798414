import { useStore } from '@livekatsomo/suspense';
import { NettilippuStatusContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that provides access to the Nettilippu status and error state.
 * @returns An object containing the current Nettilippu status, error, and any additional context provided by the NettilippuStatusProvider.
 * @throws An error if used outside of a NettilippuStatusProvider.
 */
export function useNettilippuStatus() {
  const context = useContext(NettilippuStatusContext);
  if (context === undefined) {
    throw new Error(
      'useNettilippuStatus must be used within NettilippuStatusProvider',
    );
  }
  const { store, ...rest } = context;
  const [nettilippuStatus, error] = useStore(store);

  return { nettilippuStatus, error, ...rest };
}
