import { AssetDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  AssetsContextModel,
  EventAssetsContext,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEventAssets extends Omit<AssetsContextModel, 'store'> {
  /**
   * The event assets.
   */
  assets: AssetDoc[] | null;
  /**
   * Any errors that occurred while fetching the event assets.
   */
  error: Error | null;
}

/**
 * A hook that provides access to event assets and error state.
 * @returns An object containing the event assets and error state.
 * @throws Will throw an error if used outside of the EventAssetsProvider.
 */
export function useEventAssets(): UseEventAssets {
  const context = useContext(EventAssetsContext);
  if (context === undefined) {
    throw new Error('useEventAssets must be used within EventAssetsProvider');
  }

  const { store, ...rest } = context;

  const [assets, error] = useStore(store);

  return {
    assets,
    error,
    ...rest,
  };
}
