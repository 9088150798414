import { FormSubmissionDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  SubmissionsContext,
  SubmissionsContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseSubmissions extends Omit<SubmissionsContextModel, 'store'> {
  /**
   * Form submissions.
   */
  submissions: FormSubmissionDoc[] | null;
  /**
   * Any errors that occurred while fetching the form submissions.
   */
  error: Error | null;
}

/**
 * A hook that provides access to form submissions and error state.
 * @returns An object containing the form submissions and error state.
 * @throws Will throw an error if used outside of the SubmissionsProvider.
 */
export function useSubmissions(): UseSubmissions {
  const context = useContext(SubmissionsContext);
  if (context === undefined) {
    throw new Error('useSubmissions must be used within SubmissionsProvider');
  }

  const { store, ...rest } = context;

  const [submissions, error] = useStore(store);

  return {
    submissions,
    error,
    ...rest,
  };
}
