import { EventConfigDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  EventConfigContext,
  eventConfigContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEventConfig extends Omit<eventConfigContextModel, 'store'> {
  /**
   * The event configuration.
   */
  eventConfig: EventConfigDoc | null;
  /**
   * Any errors that occurred while fetching the event configuration.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the event configuration and error state.
 * @returns An object containing the event configuration, error state, and other context values.
 * @throws If used outside of the EventConfigProvider.
 */
export function useEventConfig(): UseEventConfig {
  const context = useContext(EventConfigContext);
  if (context === undefined) {
    throw new Error('useEventConfig must be used within EventConfigProvider');
  }
  const { store, ...rest } = context;

  const [eventConfig, error] = useStore(store);
  return { eventConfig, error, ...rest };
}
