import { useStore } from '@livekatsomo/suspense';
import {
  ModeratorContext,
  ModeratorContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseModerator extends Omit<ModeratorContextModel, 'store'> {
  /**
   * A boolean value indicating whether the user is a moderator.
   */
  isModerator: boolean;
}

/**
 * A hook that provides information about the current user's moderator status.
 * @returns An object containing a boolean value indicating whether the user is a moderator.
 */
export function useModerator(): UseModerator {
  const context = useContext(ModeratorContext);
  if (context === undefined) {
    throw new Error('useModerators must be used within ModeratorsProvider');
  }
  const { store } = context;
  const [isModerator] = useStore(store);

  return {
    isModerator: Boolean(isModerator),
  };
}
