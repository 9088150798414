import { User } from '@livekatsomo/models';
import { UserContext, UserContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseUser extends UserContextModel {
  /**
   * The current user.
   */
  user: User | null;
}

/**
 * A hook that returns the current user context from the nearest `UserProvider` component.
 * @returns The current user context.
 * @throws Throws an error if `useUser` is used outside of a `UserProvider` component.
 */
export function useUser(): UseUser {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within UserProvider');
  }

  return context;
}
