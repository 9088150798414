import { SponsorDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  SponsorsContext,
  SponsorsContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';
import { useEvent } from './useEvent';

export interface UseSponsors extends Omit<SponsorsContextModel, 'store'> {
  /**
   * The sponsors data.
   */
  sponsors: SponsorDoc[] | null;
  /**
   * Any errors that occurred while fetching the sponsors data.
   */
  error: Error | null;
}

/**
 * A custom hook that provides access to the sponsors data and error state.
 * @returns An object containing the sponsors data, error state, and other context values.
 * @throws Will throw an error if used outside of the SponsorProvider.
 */
export function useSponsors(): UseSponsors {
  const context = useContext(SponsorsContext);
  const { event } = useEvent();

  if (context === undefined) {
    throw new Error('useSponsor must be used within SponsorProvider');
  }
  if (!event) throw new Error('Event not found');

  const { store, ...rest } = context;
  const [sponsors, error] = useStore(store);

  const sortedSponsors =
    sponsors && event.sponsors
      ? sponsors
          .slice()
          .sort(
            (a, b) =>
              (event.sponsors || []).indexOf(a.id) -
              (event.sponsors || []).indexOf(b.id),
          )
      : [];

  return { sponsors: sortedSponsors, error, ...rest };
}
