import { EventDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { EventContext, EventContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEvent extends Omit<EventContextModel, 'store'> {
  /**
   * The current event.
   */
  event: EventDoc | null;
  /**
   * Any errors that occurred while fetching the event.
   */
  error: Error | null;
}

/**
 * Custom hook that provides access to the current event and error state from the EventContext.
 * @throws If used outside of the EventProvider.
 * @returns An object containing the current event, error, and any additional properties passed down from the EventProvider.
 */
export function useEvent(): UseEvent {
  const context = useContext(EventContext);
  if (context === undefined) {
    throw new Error('useEvent must be used within EventProvider');
  }
  const { store, ...rest } = context;
  const [event, error] = useStore(store);

  return { event, error, ...rest };
}
