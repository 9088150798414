import { DownloadFileDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  DownloadFilesContextModel,
  EventDownloadFilesContext,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseEventDownloadFiles
  extends Omit<DownloadFilesContextModel, 'store'> {
  /**
   * The event downloadFiles.
   */
  downloadFiles: DownloadFileDoc[] | null;
  /**
   * Any errors that occurred while fetching the event downloadFiles.
   */
  error: Error | null;
}

/**
 * A hook that provides access to event downloadFiles and error state.
 * @returns An object containing the event downloadFiles and error state.
 * @throws Will throw an error if used outside of the EventDownloadFilesProvider.
 */
export function useEventDownloadFiles(): UseEventDownloadFiles {
  const context = useContext(EventDownloadFilesContext);
  if (context === undefined) {
    throw new Error(
      'useEventDownloadFiles must be used within EventDownloadFilesProvider',
    );
  }

  const { store, ...rest } = context;

  const [downloadFiles, error] = useStore(store);

  return {
    downloadFiles,
    error,
    ...rest,
  };
}
