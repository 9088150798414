import { ChatMessageDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { ChatContext, ChatContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseChat extends Omit<ChatContextModel, 'store'> {
  chatMessages: ChatMessageDoc[] | null;
  error: Error | null;
}

/**
 * Custom hook that provides access to the chat messages and error state from the ChatContext.
 * @returns An object containing the chatMessages, error, and other properties from the ChatContext.
 * @throws If used outside of the ChatProvider.
 */
export function useChat(): UseChat {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within ChatProvider');
  }

  const { store, ...rest } = context;
  const [chatMessages, error] = useStore(store);
  return {
    chatMessages,
    error,
    ...rest,
  };
}
