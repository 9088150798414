import { User } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { SigninProcessStatus } from '@livekatsomo/types';
import {
  AuthenticationContext,
  AuthenticationContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * Represents the authentication state of the user.
 */
export interface UseAuthentication
  extends Omit<AuthenticationContextModel, 'store'> {
  /**
   * The currently signed-in user. If no user is signed in, this is null.
   */
  user: User | null;
  /**
   * The current status of the authentication process.
   */
  processing: SigninProcessStatus;
  /**
   * Any errors that occurred while authenticating the user.
   */
  error: Error | null;
  /**
   * Whether the user is authenticated.
   */
  authenticationSuccess: boolean;
  /**
   * Whether the user is an admin.
   */
  isAdmin: boolean;
}

/**
 * A custom hook that provides authentication-related functionality to components.
 * @returns An object containing authentication-related functions and data.
 */
export function useAuthentication(): UseAuthentication {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within AuthenticationProvider',
    );
  }

  const { store, error: authError, ...rest } = context;
  const [user, error] = useStore(store);
  return {
    ...rest,
    user,
    error: authError || error,
    authenticationSuccess: !!user && !user.isAnonymous,
    isAdmin: Boolean(user?.roles?.includes('admin')),
  };
}
