import { useStore } from '@livekatsomo/suspense';
import { ServerConnectionContext } from '@livekatsomo/web/contexts';

import { useContext } from 'react';

export type UseServerConnectionStatus = boolean | null;

/**
 * A hook that returns the current server connection status.
 * @returns The current server connection status.
 * @throws An error if used outside of a ServerConnectionProvider.
 */
export function useServerConnectionStatus(): UseServerConnectionStatus {
  const context = useContext(ServerConnectionContext);
  if (context === undefined) {
    throw new Error(
      'useServerConnectionStatus must be used within ServerConnectionProvider',
    );
  }
  const { store } = context;
  const [status] = useStore(store);

  return status;
}
