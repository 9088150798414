import { InvitationDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  InvitationsContext,
  InvitationsContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseInvitations
  extends Omit<InvitationsContextModel, 'invitationsStore'> {
  /**
   * The invitations for the current user.
   */
  invitations: InvitationDoc[] | null;
  /**
   * Any errors that occurred while fetching the invitations.
   */
  error: Error | null;
}

/**
 * Custom hook that provides access to the invitations store and error state.
 * @returns An object containing the invitations store, error state, and context.
 * @throws If used outside of the InvitationsProvider.
 */
export function useInvitations(): UseInvitations {
  const context = useContext(InvitationsContext);
  if (context === undefined) {
    throw new Error('useInvitations must be used within InvitationsProvider');
  }
  const { invitationsStore } = context;
  const [invitations, error] = useStore(invitationsStore);

  return {
    ...context,
    error,
    invitations,
  };
}
