import {
  EmailNotVerifiedError,
  KatsomoAuthError,
  NoEmailError,
} from '@livekatsomo/custom-errors';
import { useLoggedInUser } from './useLoggedInUser';
import { UseAuthentication } from './useAuthentication';
import { User } from '@livekatsomo/models';

export type UseLoggedInVerifiedUser = Omit<UseAuthentication, 'user'> &
  (
    | {
        /**
         * The currently logged in user with email verified.
         */
        user: User & { emailVerified: true };
        /**
         * Error is always null if the user with email verified is logged in.
         */
        error: null;
      }
    | {
        /**
         * User is always null if the user is not logged in or email is not verified.
         */
        user: User | null;
        /**
         * The error that occurred while fetching the user.
         */
        error: KatsomoAuthError;
      }
  );

/**
 * Custom hook that returns the logged in user if they are verified, otherwise returns an error.
 * @returns An object containing the user and error (if any).
 */
export function useLoggedInVerifiedUser(): UseLoggedInVerifiedUser {
  const { user, error, ...rest } = useLoggedInUser();

  if (error) {
    return {
      user: user,
      error,
      ...rest,
    };
  }

  let verifiedError: KatsomoAuthError | null = null;
  if (user.emailVerified) {
    return {
      user: user as User & { emailVerified: true },
      error: null,
      ...rest,
    };
  } else {
    if (user?.email) {
      verifiedError = new EmailNotVerifiedError('User is not verified', {
        email: user.email,
      });
    } else {
      verifiedError = new NoEmailError('No Email in user profile', {
        user,
      });
    }
    return {
      user: user,
      error: verifiedError,
      ...rest,
    };
  }
}
