import { ModeratorDoc, PreAuthorizedModerator } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  ModeratorsContext,
  ModeratorsContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseModerators
  extends Omit<
    ModeratorsContextModel,
    'moderatorsStore' | 'preAuthorizedModeratorsStore'
  > {
  /**
   * The moderators for the current event.
   */
  moderators: ModeratorDoc[] | null;
  /**
   * The pre-authorized moderators for the current event.
   */
  preAuthorizedModerators: PreAuthorizedModerator[] | null;
  /**
   * Any errors that occurred while fetching the moderators.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the moderators and pre-authorized moderators.
 * @returns An object containing the moderators, pre-authorized moderators, and any errors that occurred while fetching the data.
 * @throws An error if used outside of a ModeratorsProvider.
 */
export function useModerators(): UseModerators {
  const context = useContext(ModeratorsContext);
  if (context === undefined) {
    throw new Error('useModerators must be used within ModeratorsProvider');
  }
  const { moderatorsStore, preAuthorizedModeratorsStore } = context;
  const [moderators, moderatorsError] = useStore(moderatorsStore);
  const [preAuthorizedModerators, preAuthorizedModeratorsError] = useStore(
    preAuthorizedModeratorsStore,
  );

  return {
    ...context,
    moderators,
    preAuthorizedModerators,
    error: moderatorsError || preAuthorizedModeratorsError,
  };
}
