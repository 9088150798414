import { PreAuthorizedUserDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  PreAuthorizedUsersContext,
  PreAuthorizedUsersContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UsePreAuthorizedUsers
  extends Omit<PreAuthorizedUsersContextModel, 'store'> {
  /**
   * The pre-authorized users data.
   */
  preAuthorizedUsers: PreAuthorizedUserDoc[] | null;
  /**
   * Any errors that occurred while fetching the pre-authorized users data.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the pre-authorized users data and error state.
 * @returns An object containing the pre-authorized users data, error state, and other context values.
 * @throws An error if used outside of the PreAuthorizedUsersProvider.
 */
export function usePreAuthorizedUsers(): UsePreAuthorizedUsers {
  const context = useContext(PreAuthorizedUsersContext);
  if (context === undefined) {
    throw new Error(
      'usePreAuthorizedUsers must be used within PreAuthorizedUsersProvider',
    );
  }
  const { store, ...rest } = context;
  const [preAuthorizedUsers, error] = useStore(store);

  return { preAuthorizedUsers, error, ...rest };
}
