import { useStore } from '@livekatsomo/suspense';
import { WallsIOContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * Custom React hook that provides access to WallsIOContext and returns posts and error.
 * @returns An object containing posts, error, and other context values.
 * @throws If used outside of WallsIOProvider.
 */
export function useWalls() {
  const context = useContext(WallsIOContext);
  if (context === undefined) {
    throw new Error('useWalls must be used within WallsIOProvider');
  }
  const { store, ...rest } = context;
  const [posts, error] = useStore(store);

  return { posts, error, ...rest };
}
