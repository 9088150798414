import { SlideDeckDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import {
  SlideDecksContext,
  SlideDecksContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseSlideDecks extends Omit<SlideDecksContextModel, 'store'> {
  /**
   * The slide decks data.
   */
  slideDecks: SlideDeckDoc[] | null;
  /**
   * Any errors that occurred while fetching the slide decks data.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the slide decks data and error state.
 * @returns An object containing the slide decks data, error state, and other context values.
 * @throws An error if used outside of the SlideDecksProvider.
 */
export function useSlideDecks(): UseSlideDecks {
  const context = useContext(SlideDecksContext);
  if (context === undefined) {
    throw new Error('useSlideDecks must be used within SlideDecksProvider');
  }

  const { store, ...rest } = context;

  const [slideDecks, error] = useStore(store);

  return {
    slideDecks,
    error,
    ...rest,
  };
}
