import { TrackDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { TrackContext, TrackContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseTrack extends Omit<TrackContextModel, 'store'> {
  /**
   * The track function.
   */
  track: TrackDoc | null;
  /**
   * Any errors that occurred while fetching the track.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the tracking functionality.
 * @returns An object containing the track function, error state, and any additional context provided by the TrackProvider.
 * @throws If used outside of a TrackProvider.
 */
export function useTrack(): UseTrack {
  const context = useContext(TrackContext);
  if (context === undefined) {
    throw new Error('useTrack must be used within TrackProvider');
  }
  const { store, ...rest } = context;

  const [track, error] = useStore(store);

  return { track, error, ...rest };
}
