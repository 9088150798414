import { KatsomoError, KatsomoErrorCodes } from '@livekatsomo/custom-errors';
import { AdminUser } from '@livekatsomo/models';
import { UseAuthentication } from './useAuthentication';
import { useLoggedInVerifiedUser } from './useLoggedInVerifiedUser';

/**
 * Represents the result of calling the `useAdminUser` hook.
 * If the user is authenticated and is an admin, `user` will be an `AdminUser` object and `error` will be `null`.
 * If the user is not authenticated or is not an admin, `user` will be `null` and `error` will be a `KatsomoError` object.
 */
export type UseAdminUser =
  | (Omit<UseAuthentication, 'user'> & {
      user: AdminUser;
      error: null;
    })
  | {
      user: null;
      error: KatsomoError;
    };

/**
 * Custom hook that returns the logged in user if they have the 'admin' role.
 * If the user is not logged in or does not have the 'admin' role, an error is returned.
 * @returns An object containing the logged in user and any errors.
 */
export function useAdminUser(): UseAdminUser {
  const { error, user, ...rest } = useLoggedInVerifiedUser();

  if (error) {
    return {
      user: null,
      error,
      ...rest,
    };
  }

  if (user?.roles?.includes('admin')) {
    return {
      user: user as AdminUser,
      error: null,
      ...rest,
    };
  } else {
    return {
      user: null,
      error: new KatsomoError(
        'User is not admin',
        KatsomoErrorCodes.UNAUTHORIZED,
      ),
      ...rest,
    };
  }
}
