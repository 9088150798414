import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect, useRef, useState, useTransition } from 'react';
import { useServerConnectionStatus } from './useServerConnection';

/**
 * A hook that informs the user of their online status.
 */
export function useOnlineStatusInformer() {
  const online = useServerConnectionStatus();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [offline, setOffline] = useState<SnackbarKey>();
  const offlineCountDown = useRef<NodeJS.Timeout>();
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    console.log('online', online);
    startTransition(() => {
      if (online === true && offline) {
        closeSnackbar(offline);
        setOffline(undefined);
        clearTimeout(offlineCountDown.current);
        offlineCountDown.current = undefined;
      }
      if (online === false && !offline && !offlineCountDown.current) {
        offlineCountDown.current = setTimeout(() => {
          const offlineKey = enqueueSnackbar(
            'Browser is offline. There is no connection to service at the moment.',
            {
              variant: 'error',
              persist: true,
            },
          );
          setOffline(offlineKey);
        }, 5000);
      }
    });
    return () => {
      if (offlineCountDown.current) {
        clearTimeout(offlineCountDown.current);
        offlineCountDown.current = undefined;
      }
    };
  }, [closeSnackbar, enqueueSnackbar, offline, online, startTransition]);
}
