import { SlideImageDoc } from '@livekatsomo/models';
import { emptyStore, useStore } from '@livekatsomo/suspense';
import {
  SlideDeckContext,
  SlideDeckContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseSlides extends Omit<SlideDeckContextModel, 'store'> {
  /**
   * The slides in the slide deck.
   */
  slides: SlideImageDoc[] | null;
  /**
   * Any errors that occurred while fetching the slide deck.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the current slide deck context.
 * @returns An object containing the current slides, error, and any additional context provided by the SlideDeckProvider.
 * @throws An error if used outside of a SlideDeckProvider.
 */
export function useSlideDeck(): UseSlides {
  const context = useContext(SlideDeckContext);
  if (context === undefined) {
    throw new Error('useSlides must be used within SlideDeckProvider');
  }

  const { store, ...rest } = context;
  const [slides, error] = useStore(store || emptyStore);
  return {
    slides,
    error,
    ...rest,
  };
}
