import {
  DocumentEditorContext,
  DocumentEditorContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * A hook that provides access to the document editor context.
 * @returns The document editor context model.
 * @throws Throws an error if used outside of the DocumentEditorProvider.
 */
export function useDocumentEditor(): DocumentEditorContextModel {
  const context = useContext(DocumentEditorContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentEditor must be used within DocumentEditorProvider',
    );
  }

  return context;
}
