import { StreamDoc } from '@livekatsomo/models';
import { useStore } from '@livekatsomo/suspense';
import { StreamsContext, StreamsContextModel } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UseStreams extends Omit<StreamsContextModel, 'store'> {
  /**
   * The streams data.
   */
  streams: StreamDoc[] | null;
  /**
   * Any errors that occurred while fetching the streams data.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the streams data and error state.
 * @returns An object containing the streams data, error state, and other context values.
 * @throws An error if used outside of the StreamsProvider.
 */
export function useStreams(): UseStreams {
  const context = useContext(StreamsContext);
  if (context === undefined) {
    throw new Error('useStreams must be used within StreamsProvider');
  }
  const { store, ...rest } = context;
  const [streams, error] = useStore(store);

  return { streams, error, ...rest };
}
