import { TrackPlaybackHistoryDoc } from '@livekatsomo/models';
import { emptyStore, useStore } from '@livekatsomo/suspense';
import {
  PlaybackHistoryContext,
  PlaybackHistoryContextModel,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export interface UsePlaybackHistory
  extends Omit<PlaybackHistoryContextModel, 'store'> {
  /**
   * The playback history.
   */
  history: TrackPlaybackHistoryDoc[] | null;
  /**
   * Any errors that occurred while fetching the playback history.
   */
  error: Error | null;
}

/**
 * A hook that provides access to the playback history and error state.
 * @returns An object containing the playback history, error state, and other context values.
 * @throws If used outside of a PlaybackHistoryProvider.
 */
export function usePlaybackHistory(): UsePlaybackHistory {
  const context = useContext(PlaybackHistoryContext);
  if (context === undefined) {
    throw new Error(
      'usePlaybackHistory must be used within PlaybackHistoryProvider',
    );
  }

  const { store, ...rest } = context;
  const [history, error] = useStore(store || emptyStore);

  return { history, error, ...rest };
}
