import { useStore } from '@livekatsomo/suspense';
import { ServerTimeOffsetContext } from '@livekatsomo/web/contexts';
import { useContext } from 'react';

export type UseServerTimeOffset = number;

/**
 * A hook that returns the server time offset in milliseconds.
 * @returns The server time offset in milliseconds.
 * @throws If used outside of a ServerConnectionProvider.
 */
export function useServerTimeOffset(): UseServerTimeOffset {
  const context = useContext(ServerTimeOffsetContext);
  if (context === undefined) {
    throw new Error(
      'useServerTimeOffset must be used within ServerConnectionProvider',
    );
  }
  const { store } = context;
  const [offset] = useStore(store);

  return offset || 0;
}
