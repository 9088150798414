import { ThemeInDocument, ThemeOverrides } from '@livekatsomo/models';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { useMemo } from 'react';

/**
 * Returns a theme object created by merging various theme options.
 *
 * @param event - An object containing event-related theme options.
 * @param event.baseTheme - The base theme to use.
 * @param event.customer - An object containing customer-related theme options.
 * @param event.customer.theme - The customer theme to use.
 * @param event.themeOverrides - Additional theme overrides to apply.
 * @param track - An object containing track-related theme options.
 * @param track.themeOverrides - Additional theme overrides to apply.
 * @param customerOverrides - Additional customer theme overrides to apply.
 * @returns The merged theme object.
 */
export function useEventTheme(
  event: {
    baseTheme?: ThemeInDocument | null;
    customer?: { theme?: ThemeInDocument };
    themeOverrides?: ThemeOverrides;
  } | null,
  track?: { themeOverrides?: ThemeOverrides } | null,
  customerOverrides?: ThemeOverrides,
) {
  return useMemo(() => {
    let mergedOptions = {};
    if (event?.baseTheme === undefined && event?.customer?.theme) {
      mergedOptions = deepmerge(mergedOptions, event?.customer?.theme);
    }
    if (event?.baseTheme) {
      mergedOptions = deepmerge(mergedOptions, event.baseTheme);
    }
    if (event?.themeOverrides) {
      mergedOptions = deepmerge(mergedOptions, event.themeOverrides);
    }
    if (track?.themeOverrides) {
      mergedOptions = deepmerge(mergedOptions, track.themeOverrides);
    }
    if (customerOverrides) {
      mergedOptions = deepmerge(mergedOptions, customerOverrides);
    }

    return createTheme(mergedOptions);
  }, [
    event?.baseTheme,
    event?.customer?.theme,
    event?.themeOverrides,
    track?.themeOverrides,
    customerOverrides,
  ]);
}
